// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-og-templates-fabric-base-js": () => import("./../../../src/og-templates/fabric_base.js" /* webpackChunkName: "component---src-og-templates-fabric-base-js" */),
  "component---src-og-templates-fabric-js": () => import("./../../../src/og-templates/fabric.js" /* webpackChunkName: "component---src-og-templates-fabric-js" */),
  "component---src-og-templates-fabric-type-js": () => import("./../../../src/og-templates/fabric_type.js" /* webpackChunkName: "component---src-og-templates-fabric-type-js" */),
  "component---src-og-templates-state-js": () => import("./../../../src/og-templates/state.js" /* webpackChunkName: "component---src-og-templates-state-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-fabrics-of-india-js": () => import("./../../../src/pages/fabrics-of-india.js" /* webpackChunkName: "component---src-pages-fabrics-of-india-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-fabric-base-js": () => import("./../../../src/templates/fabricBase.js" /* webpackChunkName: "component---src-templates-fabric-base-js" */),
  "component---src-templates-fabric-js": () => import("./../../../src/templates/fabric.js" /* webpackChunkName: "component---src-templates-fabric-js" */),
  "component---src-templates-fabric-type-js": () => import("./../../../src/templates/fabricType.js" /* webpackChunkName: "component---src-templates-fabric-type-js" */),
  "component---src-templates-state-js": () => import("./../../../src/templates/state.js" /* webpackChunkName: "component---src-templates-state-js" */)
}

